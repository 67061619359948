<template>
    <div class="flex flex-col">
        <div class="md:text-2xl text-xl text-zinc-800 arial_rounded mb-4">Progetti salvati</div>

        <div class="bg-zinc-200 2xl:w-3/5 xl:w-3/4 w-full text-sm">
            <div class="border-b p-3 text-left border-zinc-300 w-full text-base arial_rounded text-zinc-800">Nome progetto</div>
        </div>
        <div class="table border-collapse table-auto 2xl:w-3/5 xl:w-3/4 w-full text-sm">
            
            <div class="table-row-group bg-white">
                <div v-for="project in projects" class="table-row">
                    <div class="table-cell border-b border-zinc-200 text-zinc-700 py-4">{{ project.name }}</div>
                    <div class="table-cell border-b border-zinc-200 py-2 text-right w-[110px]">
                        <button v-on:click="trampoline_show_wait_and_open_report(project.id)">
                            <div class="rounded azzurro_schneider p-2 text-white  text-center arial_rounded hover:bg-zinc-700 ">
                                APRI
                                REPORT
                            </div>
                        </button>
                    </div>
                    <div class="table-cell border-b border-zinc-200 py-2 text-right w-[120px] ml-5">
                        <button v-on:click="open_delete_report_confirm_modal(project.id)">
                            <div class="rounded  p-2 text-white  text-center arial_rounded hover:bg-zinc-700  bg-red-700 ml-[10px]">
                                ELIMINA
                                REPORT
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>



    <div v-cloak v-if="confirm_delete_modal_shown" class="h-screen w-screen bg-[#23232330] fixed top-0 left-0 z-40 ">
        <div class=" flex justify-center items-center h-full ">
            <div class="modal_stato_utente 2xl:w-3/5 xl:w-3/5 lg:w-3/5 md:w-4/5 sm:w-4/5 w-4/5 h-4/5 sm:h-auto">
                <div class="flex flex-col justify-between text-center">
                    <div class="text-xl testo_bold azzurro_schneider_testi arial_rounded">ELIMINA REPORT</div>
                    <div class="text-sm mt-4">Cliccando sul tasto ELIMINA cancellarai in modo definitivo il report
                        selezionato e non sarà recuperabile in alcun modo.</div>
                </div>
                <div class=" mt-1 text-sm testi_attivi testo_bold text-center">
                    <button v-on:click="close_delete_report_confirm_modal()"
                        class="bottone_procedi mt-8 rounded cursor-pointer  py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold w-[100] mr-2">
                        ANNULLA
                    </button>
                    <button v-on:click="confirm_deletion()"
                        class="bottone_procedi mt-8 rounded cursor-pointer  py-2 text-sm  hover:bg-zinc-400 text-white text-center testo_bold w-[100] bg-red-700 ml-2">
                        ELIMINA
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>

var SAVED_PROJECTS_URL = "/api/user/saved-projects/list";

export default {
    name: 'SavedProjects',
    data() {
        return {
            projects: [],
            confirm_delete_modal_shown: false,
            report_to_delete: null
        };
    },
    methods: {
        load_projects: function () {
            let self = this;

            if (get_query()["user_id"]) {
                SAVED_PROJECTS_URL += "?user_id=" + get_query()["user_id"];
            }

            axios.get(SAVED_PROJECTS_URL)
                .then(function (response) {
                    self.projects = response.data.surveys;
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        open_delete_report_confirm_modal: function (survey_id) {
            this.confirm_delete_modal_shown = true;
            this.report_to_delete = survey_id;
        },
        close_delete_report_confirm_modal: function () {
            this.confirm_delete_modal_shown = false;
            this.report_to_delete = null;
        },
        confirm_deletion: function () {
            let self = this;
            axios.post("/api/user/saved-projects/delete", { survey_id: self.report_to_delete })
                .then(function (response) {
                    self.load_projects();
                    self.close_delete_report_confirm_modal();
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        trampoline_show_wait_and_open_report: function(project_id) {
            show_wait_and_open_report(project_id);
        }
    },
    mounted: function () {
        this.load_projects();
    }
};
</script>
  
<style scoped></style>
  