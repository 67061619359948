<template>
    <div v-cloak v-if="survey.data" class="md:container md:mx-auto lg:px-16 xl:px-32 px-3 inizio_pagina"
        id="anchor-top-page">
        <div class="flex flex-col md:text-2xl text-xl text-zinc-800 arial_rounded mb-5">Calcola i tuoi consumi</div>
        <div class="flex flex-col lg:flex-row justify-between">
            <div class="flex flex-col">
                <div class="flex flex-col box_edificio_selezionato mb-5 2xl:w-[300px] xl:w-[260px] lg:w-[250px] md:w-[240px] sm:w-[246px] w-[250px]">
                    <div class="flex flex-row items-center text-white 2xl:text-2xl xl:text-xl text-xl leading-6 arial_rounded p-0">
                        <img v-bind:src="'/static/images/' + survey.environment + '.svg'" class="2xl:w-24 2xl:h-24 xl:w-24 xl:h-24 lg:w-20 lg:h-20 md:w-20 md:h-20 sm:h-20 sm:w-20 w-20 h-20 m-1">
                        {{ survey.data.environment.name }}
                    </div>
                </div>
            </div>
            <div class="flex arial_rounded">
                <div class="grid grid-cols-7 xl:gap-4 md:gap-2 sm:gap-3 gap-2 mb-5 lg:w-full">
                    <!-- phase: heating -->
                    <button v-bind:disabled="is_phase_locked('heating')" v-on:click="switch_to_phase('heating')"
                        class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'heating', 'locked': is_phase_locked('heating') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <line class="st0" x1="8.4" y1="60.8" x2="61.6" y2="60.8" />
                                <path class="st0"
                                    d="M16.2,9.2c0,0-1.7,2.9-1.7,6.9s1.2,7.3,3.8,12.2s4.6,8.7,4.6,12.6s-1.4,10-2.9,12.2" />
                                <path class="st0"
                                    d="M30.3,9.2c0,0-1.7,2.9-1.7,6.9s1.2,7.3,3.8,12.2s4.6,8.7,4.6,12.6S35.5,51,34,53.1" />
                                <path class="st0"
                                    d="M44.3,9.2c0,0-1.7,2.9-1.7,6.9s1.2,7.3,3.8,12.2S51,37,51,40.9S49.5,51,48,53.1" />
                            </svg>
                        </div>
                        <div class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Riscaldamento
                        </div>
                    </button>
                    <!-- phase: hot_water-->
                    <button v-bind:disabled="is_phase_locked('hot_water')" v-on:click="switch_to_phase('hot_water')" class="box phase-box" v-bind:class="{ 'current': survey.current_phase == 'hot_water', 'locked': is_phase_locked('hot_water') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <g>
                                    <g>
                                        <path class="st0" d="M28.3,23.6h-6.4c-2.9,0-5.2-2.3-5.2-5.2v0c0-2.9,2.3-5.2,5.2-5.2h20.9c2.9,0,5.2,2.3,5.2,5.2v0
                      c0,2.9-2.3,5.2-5.2,5.2h-7.4" />
                                        <polyline class="st0" points="10.4,34.5 28.3,34.5 28.3,23.7" />
                                        <path class="st0" d="M10.4,48.1c0,0,30.6,0,32.8,0c2.2,0,3.7,1.5,3.7,3.7s0,5.3,0,5.3h12.6c0,0,0,0.6,0-8.2S47.8,35,43.9,35
                      s-8.7,0-8.7,0V23.6" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Acqua calda sanitaria
                        </div>
                    </button>
                    <!-- phase: cooling-->
                    <button v-bind:disabled="is_phase_locked('cooling')" v-on:click="switch_to_phase('cooling')" class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'cooling', 'locked': is_phase_locked('cooling') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia disable h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <g>
                                    <line class="st0" x1="35.1" y1="42.1" x2="35.1" y2="60.6" />
                                    <line class="st0" x1="35.1" y1="9.4" x2="35.1" y2="28" />
                                    <polyline class="st0" points="26.7,13.2 35.1,21.5 43.5,13.2" />
                                    <polyline class="st0" points="43.5,56.8 35.1,48.5 26.7,56.7" />
                                    <line class="st0" x1="28.9" y1="38.5" x2="12.8" y2="47.9" />
                                    <line class="st0" x1="57.1" y1="22.1" x2="41.1" y2="31.4" />
                                    <polyline class="st0" points="49.6,16.7 46.7,28.2 58,31.3" />
                                    <polyline class="st0" points="20.4,53.3 23.3,41.8 12,38.7" />
                                    <line class="st0" x1="28.9" y1="31.4" x2="12.9" y2="22" />
                                    <line class="st0" x1="57.1" y1="48" x2="41.2" y2="38.6" />
                                    <polyline class="st0" points="58.1,38.8 46.6,41.8 49.5,53.3" />
                                    <polyline class="st0" points="11.9,31.2 23.4,28.2 20.5,16.7" />
                                    <polygon class="st0"
                                        points="41.7,38.8 41.7,31.2 35.1,27.4 28.5,31.2 28.5,38.8 35.1,42.6" />
                                </g>
                            </svg>
                        </div>
                        <div
                            class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Raffrescamento
                        </div>
                    </button>
                    <!-- phase: ventilation_and_conditioning -->
                    <button v-bind:disabled="is_phase_locked('ventilation_and_conditioning')"
                        v-on:click="switch_to_phase('ventilation_and_conditioning')" class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'ventilation_and_conditioning', 'locked': is_phase_locked('ventilation_and_conditioning') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia disable h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <path class="st0"
                                    d="M43.2,52.6l2.7,7.8c2.6-0.6,6.7-1.4,10.6-4.6c3.9-3.2,4.8-5.9,4.8-8.2c0-2.3-1.3-5.4-3.2-6.8
                  c-1.9-1.4-6.2-4.2-15.6-5c-0.1-2-0.9-3.7-2.2-5l19.9-7c-0.6-2.6-1.4-6.7-4.6-10.6c-3.2-3.9-5.9-4.8-8.2-4.8c-2.3,0-5.4,1.3-6.8,3.2
                  c-1.4,1.9-4.4,6.6-5.1,16.8l0.3,0.3c-0.2,0-0.4,0-0.6,0c-1.5,0-2.8,0.4-3.9,1.2L24.1,9.4c-2.6,0.6-6.7,1.4-10.6,4.6
                  c-3.9,3.2-4.8,5.9-4.8,8.2s1.3,5.4,3.2,6.8c1.9,1.4,6.4,4.3,16.3,5.1c-0.2,0.6-0.3,1.3-0.3,2c0,1.3,0.3,2.5,1,3.6l-18.7,6.6
                  c0.6,2.6,1.4,6.7,4.6,10.6c3.2,3.9,5.9,4.8,8.2,4.8s5.4-1.3,6.8-3.2c1.3-1.8,4.1-6,5-15.1c0.1,0,0.3,0,0.4,0c1.6,0,3-0.5,4.2-1.3" />
                            </svg>
                        </div>
                        <div
                            class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Ventilazione e
                            condizionamento
                        </div>
                    </button>
                    <!-- phase: lighting -->
                    <button v-bind:disabled="is_phase_locked('lighting')" v-on:click="switch_to_phase('lighting')"
                        class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'lighting', 'locked': is_phase_locked('lighting') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia disable h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <path class="st0" d="M27.5,42.3c0,0,0-3.4,0-4.8s-1.7-3.1-1.7-3.1c-3.6-2.8-5.9-7.1-5.9-12c0-8.3,6.8-15.1,15.1-15.1
                  s15.1,6.8,15.1,15.1c0,4.9-2.3,9.3-6,12c0,0-2.4,1.8-2.4,5.6s0,4.2,0,4.7s-0.2,1.9-2,1.9s-8.4,0-9.4,0c-1.9,0-2.9,1-2.9,2.1
                  c0,1.1,0.1,2.9,2.4,2.9c1.9,0,8.7,0,9.7,0c1.6,0,2.3,1.2,2.3,2.3c0,1.1-0.3,2.7-2.1,2.7c-0.9,0-8,0-8.8,0s-2.8,0.7-2.8,2.6
                  c0,2.3,2.1,3.3,4.4,3.3c2.4,0,3.9,0,3.9,0" />
                            </svg>
                        </div>
                        <div
                            class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Illuminazione
                        </div>
                    </button>

                    <!-- phase: solar_screens -->
                    <button v-bind:disabled="is_phase_locked('solar_screens')" v-on:click="switch_to_phase('solar_screens')"
                        class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'solar_screens', 'locked': is_phase_locked('solar_screens') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia disable h-full">
                            <svg class="stroke-2 fill-none inline h-full background_svg" x="0px" y="0px" viewBox="0 0 70 70"
                                xml:space="preserve">
                                <g>
                                    <g>
                                        <line class="st0" x1="47.3" y1="34.7" x2="10.4" y2="34.7" />
                                        <line class="st0" x1="47.3" y1="28.6" x2="10.5" y2="28.6" />
                                        <line class="st0" x1="47.1" y1="22.5" x2="10.3" y2="22.5" />
                                        <line class="st0" x1="47.2" y1="16.5" x2="10.4" y2="16.5" />
                                        <path class="st0"
                                            d="M54.2,10.3c0,0-36.8,0-40.1,0s-4.4,2.3-4.4,5.8c0,1.1,0,18.3,0,20.8s2.2,4,4.5,4c1.2,0,28.2,0,30.8,0
                        c2.7,0,3.3-2.8,3.3-5.1c0-2.3,0-19.2,0-19.2c0-3.5,2.8-6.3,6.3-6.2c3.4,0.1,6,3.1,6,6.4l0,41.9c0,1.1-0.9,1.9-1.9,1.9H9.8" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div
                            class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Schermature
                            solari</div>
                    </button>
                    <!-- phase: tbm -->
                    <button v-bind:disabled="is_phase_locked('tbm_systems')" v-on:click="switch_to_phase('tbm_systems')"
                        class="box phase-box"
                        v-bind:class="{ 'current': survey.current_phase == 'tbm_systems', 'locked': is_phase_locked('tbm_systems') }">
                        <div class="phase-box-icon flex flex-row items-center justify-center box_tipologia disable h-full">
                            <svg class="stroke-2 fill-none inline h-full" x="0px" y="0px" viewBox="0 0 70 70">
                                <polyline class="st0" points="29.4,50 29.4,42.1 14.4,42.1 14.4,12.9 55.6,12.9 55.6,41.7 40.2,41.7 46.4,48.4 51.7,48.4 51.7,57.1 
                  19.4,57.1 " />
                            </svg>
                        </div>
                        <div
                            class="hidden sm:flex phase-box-name text-center text-black xl:text-[10px] text-[9px] leading-3 mt-1 testo_tipologia">
                            Sistemi TBM
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-row xl:mt-1 lg:mt-4 sm:mt-8">
            <div class="flex flex-col lg:w-3/5 w-full mb-8">
                <div v-if="page == 'data-entry'">
                    <div class="arial_rounded verde_schneider_testi text-lg">
                        {{ survey.data.systems[survey.current_phase].name }}</div>
                    <div class="text-zinc-800 xl:text-base text-sm">
                        <span v-html="survey.data.systems[survey.current_phase].description"></span>
                    </div>
                    <div class="flex flex-row mt-5 mb-10">
                        <ul>
                            <li>
                                <label class="arial_rounded verde_schneider_testi text-lg items-center flex my-2">
                                    <input v-model="survey.replies.systems[survey.current_phase].not_applicable"
                                        class="mt-0" type="checkbox" /> Impianto non presente
                                </label>
                            </li>
                        </ul>
                    </div>

                    <div v-if="survey.replies.systems[survey.current_phase].not_applicable == false"
                        class="questions-box mb-10 flex flex-col mt-5"
                        v-bind:class="{ 'disabled': survey.replies.systems[survey.current_phase].not_applicable }"
                        v-for=" question  in  survey.data.systems[survey.current_phase].questions ">
                        <div class="flex flex-col text-zinc-800 text-base testo_bold arial_rounded leading-5 mb-1">
                            {{ question.name }}
                        </div>
                        <ul>
                            <li v-for=" question_option  in  question.options" class="flex flex-row items-start width-question">
                                <label class="flex flex-row items-start text-sm my-2 leading-[1.45rem]">
                                    <input v-model="survey.replies.systems[survey.current_phase].questions[question.ref]"
                                        v-bind:value="question_option.ref" class="cursor-pointer shrink-0" type="radio" />
                                    <span>
                                        {{ question_option.description }}
                                        <button v-if="question_option.info != null" class="inline absolute ">
                                            <img v-on:click="infobox_modal_show(question_option.info.content, question_option.info.links)"
                                                src="/static/images/punto_domanda.svg" class="w-6 h-6 ml-3">
                                        </button>
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button
                            class="bottone_procedi mt-8 rounded cursor-pointer w-full py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold"
                            v-if="survey.current_phase != survey.phases.last()"
                            v-bind:disabled="!is_phase_completed(survey.current_phase)" v-on:click="goto_next_phase()">
                            PROCEDI
                        </button>
                        <button
                            class="bottone_procedi mt-8 rounded cursor-pointer w-full py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold"
                            v-if="survey.current_phase == survey.phases.last()"
                            v-bind:disabled="!is_phase_completed(survey.current_phase)"
                            v-on:click="process_replies()">CALCOLA</button>
                    </div>
                </div>
                <div v-if="page == 'scores'">
                    <div class="arial_rounded verde_schneider_testi text-lg mb-4">RISULTATI</div>
                    <div class="flex sm:flex-row flex-col">
                        <div class="questions-box flex flex-col sm:w-3/5">
                            <div class="text-black text-base testo_bold mb-8 bg-zinc-200  w-10/12 p-2">
                                Classe di automazione attuale
                                <div class="text-xl testo_bold verde_schneider_testi">{{ survey.scores.overall.value }}
                                </div>
                            </div>

                            <div class="flex flex-col text-zinc-800 text-lg testo_bold arial_rounded leading-5 mb-1">
                                Classe di automazione desiderata
                            </div>
                            <ul>
                                <li v-for=" opt  in  survey.enhancement_options.overall " class="flex flex-row items-start">
                                    <label class="flex flex-row items-start text-sm my-2 leading-[1.45rem]">
                                        <input v-model="survey.enhancement_selections.overall" v-bind:value="opt.value"
                                            class="cursor-pointer shrink-0" type="radio" />
                                        <span>
                                            {{ opt.name }}
                                        </span>
                                    </label>
                                </li>
                                <li class="flex flex-row items-start">
                                    <label class="flex flex-row items-start text-sm my-2 leading-[1.45rem]">
                                        <input v-model="survey.enhancement_selections.overall" value="SYSTEM_SPECIFIC"
                                            class="cursor-pointer shrink-0" type="radio" />
                                        <span>
                                            Seleziona per impianto
                                        </span>
                                    </label>
                                </li>
                            </ul>
                            <div v-if="survey.enhancement_selections.overall == 'SYSTEM_SPECIFIC'"
                                v-for=" system  in  survey.phases " class="sm:ml-8 mt-6">
                                <div>
                                    <div class="flex flex-col text-zinc-800 text-lg testo_bold leading-5 mb-2">
                                        {{ survey.data.systems[system].name }}
                                    </div>
                                    <div
                                        class="flex flex-row items-end  text-zinc-800  text-base testo_bold  leading-5 mb-2 bg-zinc-200  w-10/12 p-2">
                                        Classe attuale <span class="verde_schneider_testi ml-3">{{
                                            survey.scores.systems[system].value }}</span>
                                    </div>
                                    <ul>
                                        <li v-for=" opt  in  survey.enhancement_options.systems[system] "
                                            class="flex flex-row items-start">
                                            <label class="flex flex-row items-start text-sm my-2 leading-[1.45rem]">
                                                <input v-model="survey.enhancement_selections.systems[system]"
                                                    v-bind:value="opt.value" class="cursor-pointer shrink-0" type="radio" />
                                                <span>
                                                    {{ opt.name }}
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="sm:w-2/5  flex flex-col mt-6 sm:mt-0"></div>
                    </div>
                    <button
                        v-bind:disabled="survey.enhancement_selections.overall == null || survey.enhancement_selections.overall == ''"
                        v-on:click="save_modal_show()"
                        class="bottone_procedi mt-8 rounded cursor-pointer w-full py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold">
                        SALVA E GENERA REPORT
                    </button>
                </div>
            </div>
            <div class="lg:flex hidden lg:flex-col lg:w-2/5 bg-zinc-200 ml-4 h-auto pl-8 pt-8">
                <picture>
                    <source type="image/webp" v-bind:srcset="'/static/images/' + survey.environment + '.webp'">
                    <img v-bind:src="'/static/images/' + survey.environment + '.jpg'"
                        class="fixed 2xl:h-[464px] xl:h-[401px] lg:h-[336px]">
                </picture>
            </div>
        </div>
    </div>
    <div v-cloak v-if="infobox_modal.show" class="h-screen w-screen bg-[#23232330] fixed top-0 left-0 z-40 ">
        <div class=" flex justify-center items-center h-full ">
            <div class="modal_info_voci 2xl:w-3/5 xl:w-3/5 lg:w-3/5 md:w-4/5 sm:w-4/5 w-4/5 h-4/5 sm:h-auto">
                <div class="flex justify-end">
                    <img v-on:click="infobox_modal_dismiss()" src="/static/images/chiudi_azzurra.svg" class="w-6 h-6">
                </div>
                <div class="text-left mt-5 mb-3 text-sm ">
                    <span v-html="infobox_modal.content"></span>
                </div>
                <div v-for=" link  in  infobox_modal.links "
                    class="text-left mt-1 text-sm testi_attivi testo_bold underline">
                    <a v-bind:href="link.url" target="_blank">{{ link.name }}</a>
                </div>
            </div>
        </div>
    </div>
    <div v-cloak v-if="save_modal.show" class="h-screen w-screen bg-[#23232330] fixed top-0 left-0 z-40 ">
        <div class="flex justify-center items-center h-full ">
            <div class="modal_salvataggio 2xl:w-2/5 xl:w-2/5 lg:w-3/5 md:w-4/5 sm:w-4/5 w-4/5 h-4/5 sm:h-auto">
                <div class="flex justify-between">
                    <div class="text-xl testo_bold azzurro_schneider_testi arial_rounded">SALVATAGGIO</div>
                    <div><img v-on:click="save_modal_dismiss()" src="/static/images/chiudi_azzurra.svg" class="w-6 h-6">
                    </div>
                </div>
                <div class="mb-5">Per procedere al salvataggio inserisci nome del progetto e del cliente utili all'identificazione nell'area "progetti salvati" e per la personalizzazione del report:</div>
                <div class="flex flex-row items-center mb-3">
                    <div class="text-left text-sm  text-base w-28 shrink-0">
                        Nome progetto:
                    </div>
                  <input v-model="survey.name" class="border-zinc-400 border p-2 rounded focus-visible:ring text-base w-full" placeholder="Nome progetto">
                   
                </div>
                <div class="flex flex-row items-center mb-3">
                    <div class="text-left text-sm  text-base w-28 shrink-0">
                        Nome cliente:
                    </div>
                    <input v-model="survey.customer" class="border-zinc-400 border p-2 rounded focus-visible:ring text-base w-full" placeholder="Nome cliente">
                </div>
                <button v-on:click="save_and_generate_report()"
                    class="bottone_procedi mt-8 rounded cursor-pointer w-full py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold">
                    SALVA E GENERA REPORT
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
Array.prototype.last = function () {
    return this[this.length - 1];
};

export default {
    name: 'SurveyWizard',
    data() {
        return {
            page: 'data-entry',
            //page: 'scores',
            survey: {
                name: '',
                customer: '',
                environment: '',
                phases: ['heating', 'hot_water', 'cooling', 'ventilation_and_conditioning', 'lighting', 'solar_screens', 'tbm_systems'],
                // phases: ['heating'],
                current_phase: 'heating',
                data: null,
                replies: null,
                scores: null,
                enhancement_options: null,
                enhancement_selections: {
                    overall: null,
                    systems: {
                        heating: null,
                        hot_water: null,
                        cooling: null,
                        ventilation_and_conditioning: null,
                        lighting: null,
                        solar_screens: null,
                        tbm_systems: null,
                    }
                }
            },
            save_modal: {
                show: false,
            },
            infobox_modal: {
                show: false,
                title: '',
                content: '',
                links: [],
            }
        };
    },
    methods: {
        load_survey: function () {
            let self = this;
            axios.get("/api/survey/questions", {
                params: {
                    environment: self.survey.environment
                }
            })
                .then(function (response) {
                    self.survey.data = response.data;
                    self.survey.replies = self.generate_replies_datacanvas();
                    // self.survey.replies = JSON.parse("{\"systems\":{\"heating\":{\"not_applicable\":false,\"questions\":{\"1.1\":\"1.1_a\",\"1.2\":\"1.2_b\",\"1.3\":\"1.3_b\",\"1.4\":\"1.4_b\",\"1.4a\":\"1.4a_b\",\"1.5\":\"1.5_c\",\"1.6\":\"1.6_b\",\"1.7\":\"1.7_b\",\"1.8\":\"1.8_b\",\"1.9\":\"1.9_a\",\"1.10\":\"1.10_b\"}},\"hot_water\":{\"not_applicable\":false,\"questions\":{\"2.1\":\"2.1_b\",\"2.2\":\"2.2_b\",\"2.3\":\"2.3_c\",\"2.4\":\"2.4_b\"}},\"cooling\":{\"not_applicable\":false,\"questions\":{\"3.1\":\"3.1_b\",\"3.2\":\"3.2_d\",\"3.3\":\"3.3_b\",\"3.4\":\"3.4_c\",\"3.4a\":\"3.4a_c\",\"3.5\":\"3.5_c\",\"3.6\":\"3.6_b\",\"3.7\":\"3.7_b\",\"3.8\":\"3.8_c\",\"3.9\":\"3.9_c\"}},\"ventilation_and_conditioning\":{\"not_applicable\":false,\"questions\":{\"4.1\":\"4.1_b\",\"4.2\":\"4.2_b\",\"4.3\":\"4.3_b\",\"4.4\":\"4.4_b\",\"4.5\":\"4.5_b\",\"4.6\":\"4.6_b\",\"4.7\":\"4.7_b\",\"4.8\":\"4.8_b\",\"4.9\":\"4.9_b\",\"4.10\":\"4.10_b\"}},\"lighting\":{\"not_applicable\":false,\"questions\":{\"5.1\":\"5.1_b\",\"5.2\":\"5.2_b\"}},\"solar_screens\":{\"not_applicable\":false,\"questions\":{\"6.1\":\"6.1_b\"}},\"tbm_systems\":{\"not_applicable\":true,\"questions\":{\"7.1\":\"7.1_b\",\"7.2\":\"7.2_b\",\"7.3\":\"7.3_b\",\"7.4\":\"7.4_b\",\"7.5\":\"7.5_b\",\"7.6\":\"7.6_b\",\"7.7\":\"7.7_b\"}}}}");
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        process_replies: function () {
            let self = this;
            axios.post("/api/survey/calculator", {
                environment: self.survey.environment,
                replies: self.survey.replies
            })
                .then(function (response) {
                    self.survey.scores = response.data.scores;
                    self.survey.enhancement_options = response.data.enhancement_options;
                    self.page = 'scores';
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        generate_replies_datacanvas: function () {
            let replies = { systems: {} };
            for (let phase of this.survey.phases) {
                replies.systems[phase] = {};
                replies.systems[phase].not_applicable = false;
                replies.systems[phase].questions = {};
                for (let question of this.survey.data.systems[phase].questions) {
                    replies.systems[phase].questions[question.ref] = null;
                }
            }
            return replies;
        },
        is_phase_completed: function (phase) {
            let replies = this.survey.replies.systems[phase];
            if (replies.not_applicable) {
                return true;
            }
            for (let question of this.survey.data.systems[phase].questions) {
                if (replies.questions[question.ref] == null) {
                    return false;
                }
            }
            return true;
        },
        infobox_modal_dismiss: function () {
            this.infobox_modal.show = false;
        },
        infobox_modal_show: function (content, links) {
            this.infobox_modal.content = content;
            this.infobox_modal.links = links;
            this.infobox_modal.show = true;
        },
        is_phase_locked: function (phase) {
            // The first phase is never locked.
            if (phase == this.survey.phases[0]) {
                return false;
            }

            // Otherwise, the phase is locked if the previous phase is not completed.
            let previous_phase_index = this.survey.phases.indexOf(phase) - 1;
            if (previous_phase_index < 0) {
                return true;
            }
            return !this.is_phase_completed(this.survey.phases[previous_phase_index]);
        },
        switch_to_phase: function (phase) {
            this.survey.current_phase = phase;
        },
        goto_next_phase: function () {
            let self = this;
            let current_phase_index = this.survey.phases.indexOf(this.survey.current_phase);
            let next_phase_index = current_phase_index + 1;
            if (next_phase_index < this.survey.phases.length) {
                let next_phase = this.survey.phases[next_phase_index];
                this.survey.current_phase = next_phase;
                go_to_anchor("anchor-top-page", -100);
            }
        },
        save_modal_show: function () {
            this.save_modal.show = true;
        },
        save_modal_dismiss: function () {
            this.save_modal.show = false;
        },
        save_and_generate_report: function () {
            let self = this;
            axios.post("/api/survey/save", {
                name: self.survey.name,
                customer: self.survey.customer,
                environment: self.survey.environment,
                data: {
                    replies: self.survey.replies,
                    enhancement_selections: self.survey.enhancement_selections
                }
            })
                .then(function (response) {
                    let survey_id = response.data.id;
                    show_wait_and_open_report(survey_id);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted: function () {
        // Get the environment from the URL, if not present, use the default one
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        if (!params.has("env")) {
            params.set("env", "home");
        }
        this.survey.environment = params.get("env");

        this.load_survey();
    }
};
</script>
  
<style scoped></style>
  