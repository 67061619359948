<template>
    <div class="flex flex-col">
        <div class="md:text-2xl text-xl text-zinc-800 arial_rounded">Gestione utenti</div>
        <div class="md:text-base text-sm text-zinc-900 mb-4">
        </div>
        <div class="flex flex-col  md:w-full mb-5">
            <table class="border-collapse border border-slate-400 w-full">
                <thead>
                    <tr class="bg-zinc-200 arial_rounded text-zinc-600 sm:text-xs text-xs leading-4">
                        <th class="hidden sm:table-cell border border-zinc-300 p-2 text-left md:w-auto ">NOME </th>
                        <th class="hidden sm:table-cell border border-zinc-300 p-2 text-left md:w-auto ">COGNOME</th>
                        <th class="border border-zinc-300 p-2 text-left xl:w-auto  w-[200px]">
                            <div class="sm:hidden flex">NOME E COGNOME</div>E-MAIL
                        </th>

                        <th class="hidden sm:table-cell border border-zinc-300 p-2  md:w-auto w-[12px]">PERMESSI</th>
                        <th class="hidden sm:table-cell border border-zinc-300 p-2 md:w-auto w-[12px]">ULTIMO ACCESSO</th>
                        <th class="hidden sm:table-cell border border-zinc-300 p-2 md:w-auto w-[12px]">N° ACCESSI</th>
                        <th class="hidden sm:table-cell border border-zinc-300 p-2 md:w-auto w-[12px]">GIORNI MANCANTI RETENTION PERIOD</th>
                        <th class="border border-zinc-300 p-2 md:w-auto w-[12px]">MODIFICA</th>
                        <th class="border border-zinc-300 p-2 md:w-auto w-[12px]">REPORT</th>
                        <th class="border border-zinc-300 p-2 md:w-auto w-[12px]">ELIMINA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" class="text-zinc-600 sm:text-xs text-xs leading-5">
                        <td class="hidden sm:table-cell border border-zinc-300 p-1 text-left">{{ user.first_name }}</td>
                        <td class="hidden sm:table-cell border border-zinc-300 p-1 text-left">{{ user.last_name }}</td>
                        <td class="border border-zinc-300 p-1 text-left">
                            <div class="sm:hidden flex">{{ user.first_name }} {{ user.last_name }}</div>
                            {{ user.email }}
                            <div class="sm:hidden flex ">
                                <span class="arial_bold">PERMESSI:</span>
                                <p v-if="user.permissions.superuser">Superuser</p>&nbsp;<p v-if="user.permissions.standard">
                                    Standard</p>&nbsp;<p class="text-red-600" v-if="user.permissions.locked">Locked</p>
                            </div>
                            <div class="sm:hidden flex">
                                <span class="arial_bold">ULTIMO ACCESSO:</span>&nbsp;<p>{{ user.last_access }}</p>
                            </div>
                            <div class="sm:hidden flex">
                                <span class="arial_bold">N° ACCESSI:</span>&nbsp;<p>{{ user.access_count }}</p>
                            </div>
                            <div class="sm:hidden flex">
                                <span class="arial_bold">GG MANCANTI RETENTION PERIOD:</span>&nbsp;<p>{{ user.remaining_retention_days }}</p>
                            </div>
                        </td>

                        <td class="hidden sm:table-cell border border-zinc-300 p-1">
                            <a href="#" target="_blank">
                                <div class="items-center justify-center text-center  p-1  leading-3">
                                    <p v-if="user.permissions.superuser">Superuser</p>&nbsp;
                                    <p v-if="user.permissions.standard">Standard</p>
                                    <p class="text-red-600" v-if="user.permissions.locked">Locked</p>
                                </div>
                            </a>
                        </td>
                        <td class="hidden sm:table-cell border border-zinc-300 p-1">
                            <div class="flex items-center justify-center text-center  p-1">
                                {{ user.last_access }}
                            </div>
                        </td>
                        <td class="hidden sm:table-cell border border-zinc-300 p-1">
                            <div class="flex items-center justify-center text-center  p-1">
                                {{ user.access_count }}
                            </div>
                        </td>
                        <td class="hidden sm:table-cell border border-zinc-300 p-1">
                            <div class="flex items-center justify-center text-center  p-1">
                                {{ user.remaining_retention_days }}
                            </div>
                        </td>
                        <td class="border border-zinc-300 p-1 text-center">
                            <button v-on:click="open_user_editor(user)">
                                <div class="flex items-center justify-center text-center  p-1">
                                    <img src="/static/images/modifca_utente.svg" class="w-6 h-6">
                                </div>
                            </button>
                        </td>
                        <td class="border border-zinc-300 p-1 text-center">
                            <button v-on:click="open_report(user)">
                                <div class="flex items-center justify-center text-center p-1">
                                    <img src="/static/images/report.svg" class="w-6 h-6">
                                </div>
                            </button>
                        </td>
                        <td class="border border-zinc-300 p-1 text-center">
                            <button v-on:click="open_delete_user_modal(user)">
                                <div class="flex items-center justify-center text-center p-1">
                                    <img src="/static/images/elimina.svg" class="w-6 h-6">
                                </div>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>



    <div v-cloak v-if="edit_user_modal_shown" class="h-screen w-screen bg-[#23232330] fixed top-0 left-0 z-40 ">
        <div class=" flex justify-center items-center h-full ">
            <div class="modal_stato_utente 2xl:w-3/5 xl:w-3/5 lg:w-3/5 md:w-4/5 sm:w-4/5 w-4/5 h-4/5 sm:h-auto">
                <div class="flex justify-between">
                    <div class="text-xl testo_bold azzurro_schneider_testi arial_rounded">MODIFICA UTENTE</div>
                    <div><img v-on:click="dismiss_user_editor()" src="/static/images/chiudi_azzurra.svg"
                            class="w-6 h-6"></div>
                </div>
                <div class="flex flex-row mt-5 mb-2">
                    <ul>
                        <li>
                            <label class="arial_rounded verde_schneider_testi text-base items-center flex my-2">
                                <input v-model="edituserform.data.permissions.superuser" class="mt-0" type="checkbox" />
                                Super User
                            </label>
                        </li>
                        <li>
                            <label class="arial_rounded verde_schneider_testi text-base items-center flex my-2">
                                <input v-model="edituserform.data.permissions.standard" class="mt-0" type="checkbox" />
                                Utente Standard
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="text-sm testi_attivi testo_bold underline">
                    <button
                        v-on:click="edituserform.data.permissions.superuser = edituserform.data.permissions.standard = false"
                        class="rounded cursor-pointer py-2 px-2 text-sm verde_schneider hover:bg-zinc-400 text-white text-center testo_bold">
                        RIMUOVI TUTTI I PERMESSI
                    </button>
                </div>

                <div class="text-left mt-1 text-sm testi_attivi testo_bold underline">
                    <button v-on:click="save_user()"
                        class="bottone_procedi mt-8 rounded cursor-pointer w-full py-2 text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold">
                        SALVA
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div v-cloak v-if="delete_user_modal_shown" class="h-screen w-screen bg-[#23232330] fixed top-0 left-0 z-40 ">
        <div class=" flex justify-center items-center h-full ">
            <div class="modal_stato_utente 2xl:w-3/5 xl:w-3/5 lg:w-3/5 md:w-4/5 sm:w-4/5 w-4/5 h-4/5 sm:h-auto">
                <div class="flex justify-between">
                    <div class="text-xl testo_bold azzurro_schneider_testi arial_rounded">ELIMINA UTENTE</div>
                    <div><img v-on:click="dismiss_delete_user_modal()" src="/static/images/chiudi_azzurra.svg"
                            class="w-6 h-6"></div>
                </div>
                <div class="flex flex-row mt-5 mb-2">
                    <div><span class="testo_bold">Se sicuro di voler eliminare definitivamente l'utente selezionato?</span><br> 
                        Cliccando su conferma procederai all'eliminazione definitiva dell'utente e di tutti i dati a lui collegati. 
                        L'operazione è irreversibile e i dati non saranno in alcun modo recuperabili.</div>
                </div>
              

                <div class=" flex flex-row text-left mt-1 text-sm testi_attivi testo_bold mt-5">
                    <button v-on:click="dismiss_delete_user_modal()"
                        class="rounded cursor-pointer py-2 w-full text-sm azzurro_schneider hover:bg-zinc-400 text-white text-center testo_bold mr-3 ">
                       ANNULLA
                    </button>

                    <button v-on:click="confirm_delete_user()" class="bottone_procedi rounded cursor-pointer w-full py-2 text-sm bg-red-900 hover:bg-zinc-400 text-white text-center testo_bold ml-3">
                        CONFERMA
                    </button>

                </div>

            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'UserManagement',
    data() {
        return {
            users: [],
            edit_user_modal_shown: false,
            delete_user_modal_shown: false,
            createuserform: {
                data: {
                    first_name: '',
                    last_name: '',
                    email: '',
                },
                errors: {
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            },
            edituserform: {
                data: {
                    id: null,
                    permissions: {
                        superuser: false,
                        standard: false,
                    }
                }
            },
            deleteuserform: {
                data: {
                    id: null,
                }
            }
        };
    },
    methods: {
        load_users: function () {
            let self = this;
            axios.get("/api/admin/users/list")
                .then(function (response) {
                    self.users = response.data.users;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save_user: function () {
            let self = this;
            axios.post("/api/admin/users/edit", this.edituserform.data)
                .then(function (response) {
                    if (response.data.status == "success") {
                        self.load_users();
                    } else {
                        alert("Errore");
                    }
                    self.dismiss_user_editor();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        open_delete_user_modal: function (user) {
            let self = this;
            self.deleteuserform.data.id = user.id;
            self.delete_user_modal_shown = true;
        },
        dismiss_delete_user_modal: function () {
            let self = this;
            self.delete_user_modal_shown = false;
        },
        confirm_delete_user: function () {
            let self = this;
            axios.post("/api/admin/users/delete", this.deleteuserform.data)
                .then(function (response) {
                    if (response.data.status == "success") {
                        self.load_users();
                    }
                    self.deleteuserform.data.id = null;
                    self.dismiss_delete_user_modal();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        open_user_editor: function (user) {
            let self = this;
            self.edit_user_modal_shown = true;
            self.edituserform.data.id = user.id;
            self.edituserform.data.permissions = user.permissions;
        },
        dismiss_user_editor: function () {
            let self = this;
            self.edit_user_modal_shown = false;
        },
        open_report: function (user) {
            window.location.href = "/user/saved-projects?user_id=" + user.id;
        }
    },
    mounted: function () {
        this.load_users();
    }
};
</script>
  
<style scoped></style>
  