<template>
  <div v-if="shown" class="h-screen w-screen bg-[#232323c4] fixed top-0 left-0 z-40 ">
    <div class=" flex justify-center items-center h-full ">
      <div class="modal_login lg:w-[400px] md:w-[360px] sm:w-[360px]  w-4/5  h-auto">
        <div class="flex flex-col verde_chiaro_schneider mt-120 px-5">
          <a href="/index">
            <img src="/static/images/logo_heder.svg" class="sm:h-24 h-20 ml-4 sm:ml-0">
          </a>
        </div>
        <div v-if="page == 'accept-usage-license'" class="h-screen w-screen bg-[#232323c4] fixed top-0 left-0 z-40 ">
          <div class=" flex justify-center items-center h-full ">
            <div class="modal_login lg:w-[700px] md:w-[540px] sm:w-[440px]  w-4/5  h-auto">
              <div class="flex flex-col verde_chiaro_schneider mt-120 px-5">
                <a href="/index">
                  <img src="/static/images/logo_heder.svg" class="sm:h-24 h-20 ml-4 sm:ml-0">
                </a>
              </div>
              <div class="text-left mt-2  px-5">
                <div class="flex flex-col md:text-2xl text-xl text-zinc-800 arial_rounded mb-5">
                  Condizioni d'uso
                </div>
                <div class="flex flex-col text-sm text-zinc-800">


                  <div class="mb-4 text-sm input-labelized">
                    <div
                      class="h-60 overflow-y-scroll overflow-x-hidden  w-full  rounded-md border border-zinc-300 p-2 text-xs">
                      <div><span class="testo_bold text-sm">LICENZA D’USO</span></div><br>
                      <ul class="list-decimal pl-3">
                        <li>
                          <span class="testo_bold">INTRODUZIONE</span><br>
                          Questa Licenza d’uso concessa dalla Schneider Electric S.p.A., cod. fisc. 00509110011
                          (Schneider
                          Electric), all’Utente è relativa alla APP denominata “TOOL ISO52120” (app web), avente la
                          prevalente
                          funzionalità di consentire a clienti di effettuare la valutazione della classe di automazione
                          dell’edificio secondo la norma UNI EN ISO52120.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">LICENZA D'USO</span><br>
                          I presenti termini e condizioni sono destinate a descrivere e impostare le regole per
                          l’utilizzo di
                          TOOL ISO52120, e chiarire le responsabilità degli Utenti e gli impegni di Schneider Electric.
                          <br><br>
                          L’Utente con l’utilizzo della APP TOOL ISO52120 diventa titolare di un diritto di licenza non
                          esclusivo, non trasferibile, revocabile, limitato e non cedibile, concesso dalla Schneider
                          Electric
                          e si impegna, durante ogni accesso alla Piattaforma di TOOL ISO52120, al rispetto di queste
                          condizioni generali di utilizzo che si applicano a tutti i servizi disponibili sulla
                          piattaforma
                          TOOL ISO52120.
                          <br><br>
                          L’Utente dichiara di aver ottenuto da Schneider Electric tutte le informazioni necessarie sui
                          servizi offerti dalla app TOOL ISO52120.
                          <br><br>
                          Schneider Electric non può in alcun modo essere ritenuta responsabile per le decisioni prese o
                          le
                          azioni intraprese da parte dell’Utente remoto su TOOL ISO52120.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">DEFINIZIONI</span><br>
                          Utente: una persona che ha creato un account su TOOL ISO52120 e che utilizza i servizi
                          offerti.
                          <br><br>
                          Piattaforma: indica la base software e hardware su cui viene sviluppata l’applicazione TOOL
                          ISO52120.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">TOOL ISO52120 TERMINI D’USO</span><br>
                          L’accesso a TOOL ISO52120 e l’utilizzo dei servizi offerti sono forniti gratuitamente a
                          qualsiasi
                          utente. L’Utente è libero di registrarsi ai servizi offerti collegandosi all’applicazione TOOL
                          ISO52120 e creando un account Utente.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">ACCESSIBILITA’ AL TOOL ISO52120</span><br>
                          TOOL ISO52120 è accessibile on-line 24 ore al giorno, sette giorni alla settimana. La
                          Piattaforma è
                          disponibile salvo motivi di forza maggiore, la manutenzione, o in occasione di eventi
                          importanti che
                          giustifichino un’interruzione occasionale.
                          <br><br>
                          Schneider Electric si riserva il diritto, senza alcun preavviso o compensazione, di chiudere
                          temporaneamente la APP per eseguire un aggiornamento, modifiche o cambiamenti a metodi
                          operativi,
                          server, le ore di disponibilità, ecc, questo elenco non è esaustivo.
                          <br><br>
                          Schneider Electric si riserva il diritto in qualunque momento di integrare o modificare i
                          propri
                          servizi e, in particolare, a causa di cambiamenti di tecnologia, leggi e regolamenti vigenti o
                          propria offerta di servizi.
                          <br><br>
                          È responsabilità dell’Utente di garantire la scalabilità del sistema, le risorse del computer
                          e la
                          trasmissione disponibile in modo che queste risorse possano essere compatibili ed adattarsi ai
                          cambiamenti di TOOL ISO52120.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">RESPONSABILITA’ DELL’UTENTE</span><br>
                          L’Utente è l’unico responsabile della conservazione e della riservatezza della password di
                          accesso
                          e, quindi, delle conseguenze di una divulgazione volontaria o involontaria a terzi.
                          <br><br>
                          L’accesso alla APP non è possibile senza la password Utente.
                          <br><br>
                          L’Utente è tenuto ad informare immediatamente Schneider Electric qualora venga compromessa la
                          riservatezza delle password dell’Utente o di qualsiasi utilizzo riservato di terze parti di
                          cui
                          l’Utente ha la conoscenza.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">CONNESSIONE INTERNET</span><br>
                          L’Utente è responsabile per il pagamento della connessione Internet dell’Utente, la
                          disponibilità e
                          il corretto funzionamento delle apparecchiature di connessione dell’Utente, e le modalità di
                          autenticazione all’applicazione TOOL ISO52120.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">SICUREZZA DELLA PIATTAFORMA</span><br><br>
                          <ul class="list-decimal pl-2">
                            <li>
                              <span class="testo_bold">Misure di Sicurezza</span><br>
                              Schneider Electric è soggetta ad un obbligo di diligenza e si impegna a fornire i suoi
                              migliori
                              sforzi per assicurare la piena disponibilità della Piattaforma di TOOL ISO52120 e
                              funzionamento
                              complessivo soddisfacente del servizio. Schneider Electric implementa misure tecniche e
                              organizzative di sicurezza per proteggere i Dati Personali di ogni Utente contro tutti i
                              danni,
                              perdite, appropriazione indebita, intrusioni, divulgazioni, alterazioni o distruzioni. I
                              dipendenti di Schneider Electric che hanno accesso ai Dati Personali degli utenti di TOOL
                              ISO52120 sono i responsabili esclusivi della gestione della Piattaforma di TOOL ISO52120.
                              Essi
                              si impegnano per la massima riservatezza in questo senso.
                              <br><br>
                              Sono state implementate le procedure di salvaguardia fisiche ed elettroniche relative ai
                              dati
                              raccolti sul sito TOOL ISO52120. Schneider Electric adotta tutte le misure di sicurezza
                              idonee
                              alla protezione dei dati, in ogni caso non potrà in nessun modo essere ritenuta
                              responsabile per
                              la perdita dei dati degli utenti a causa di una mancanza di sicurezza della connessione
                              internet
                              utilizzata dall’Utente medesimo per accedere all’account Utente di TOOL ISO52120.
                            </li>
                            <br>
                            <li>
                              <span class="testo_bold">Assistenza Tecnica</span><br>
                              Schneider Electric offre agli utenti il support tecnico, che può essere raggiunto
                              contattando il
                              numero unico locale di pronto contatto.
                            </li>
                          </ul>

                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">PROPRIETA’ INTELLETTUALE</span><br>
                          Schneider Electric è il proprietario del sistema TOOL ISO52120, di tutti i suoi componenti e
                          di
                          tutti i lavori di sviluppo e di ricerca sul funzionamento della Piattaforma web di TOOL
                          ISO52120. È
                          vietato per l’Utente intraprendere qualsiasi azione che possa danneggiare direttamente o
                          indirettamente, i diritti di proprietà intellettuale detenuti da Schneider Electric.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">PROTEZIONE DATI PERSONALI</span><br><br>
                          <ul class="list-decimal pl-2">
                            <li>
                              <span class="testo_bold">Informativa sul trattamento dei dati personali</span><br>
                              L’informativa per il trattamento di dati personali è allegata alla presente.
                            </li>
                            <br>
                            <li>
                              <span class="testo_bold">Precisazioni</span><br>
                              La fase di login al primo accesso è necessaria per l’utilizzo dell’Applicazione; pertanto,
                              in
                              caso di mancato login, l’utente non potrà avere accesso alle funzionalità della App. Una
                              volta
                              effettuato il login, l’utente potrà quindi accedere a tutte le funzionalità dell’App senza
                              loggarsi nuovamente ad ogni accesso. Il mancato conferimento di questi dati potrebbe
                              rendere
                              impossibile l’erogazione dei servizi da parte della presente App.
                              <br><br>
                              Schneider Electric ha accesso ai dati sull’utilizzo dell’applicazione da parte degli
                              utenti,
                              come la frequenza di registrazione e le funzioni più utilizzate, al fine di migliorare
                              l’applicazione e l’esperienza dell’Utente.
                              <br><br>
                              La fase di registrazione è necessaria per l’utilizzo dell’Applicazione; pertanto, in caso
                              di
                              mancata registrazione, l’utente non potrà avere accesso alle funzionalità della
                              Applicazione.
                              Una volta effettuata la registrazione, l’utente potrà accedere a tutte le funzionalità
                              dell’App
                              autenticandosi ad ogni accesso.
                            </li>
                          </ul>
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">ESCLUSIONE DELLE RESPONSABILITA’</span><br>
                          Schneider Electric non potrà essere ritenuta responsabile delle conseguenze, potenzialmente
                          dannose
                          per l’Utente, per una decisione che l’Utente effettua sulla base degli strumenti di
                          informazione o
                          software resi disponibili attraverso TOOL ISO52120. L’Utente è il solo responsabile per le
                          decisioni
                          che l’Utente effettua.
                          <br><br>
                          Schneider Electric non potrà essere ritenuta responsabile in caso di indisponibilità
                          temporanea o
                          totale di tutto o parte dell’accesso al servizio TOOL ISO52120, di difficoltà relative al
                          tempo di
                          risposta, e, in generale, per qualsiasi difetto prestazionale.
                          <br><br>
                          L’Utente è il solo responsabile per l’uso fatto delle informazioni presenti sulla Piattaforma
                          e, di
                          conseguenza, Schneider Electric non può essere ritenuta responsabile dei danni diretti o
                          indiretti
                          derivanti dall’uso di queste informazioni. La comunicazione di codici di accesso degli utenti
                          o, in
                          generale, di qualsiasi altra informazione ritenuta riservata avviene sotto la responsabilità
                          dell’Utente. Schneider Electric non è responsabile per la registrazione e l’uso di TOOL
                          ISO52120 da
                          una persona di minore età. Schneider Electric raccomanda che i genitori / tutori garantiscano
                          che
                          nessun account di TOOL ISO52120 venga creato da una persona di età minore senza il loro previo
                          consenso.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">VALIDITA’ DEI TERMINI E CONDIZIONI GENERALI</span><br><br>
                          <ul class="list-decimal pl-2">
                            <li>
                              <span class="testo_bold">Durata degli impegni</span><br>
                              Il rapporto contrattuale tra le parti avrà effetto a partire dalla data di registrazione
                              dell’Utente e dura fino alla rimozione dell’account dell’Utente da parte del medesimo o da
                              Schneider Electric per mancato rispetto delle presenti condizioni di utilizzo o per
                              mancato
                              accesso alla Piattaforma per un periodo continuativo superiore a un anno di cui al punto
                              12.
                            </li>
                            <br>
                            <li>
                              <span class="testo_bold">Applicabilità</span><br>
                              I termini e le condizioni d’uso generali attuali sono vincolanti per gli utenti, alla loro
                              accettazione durante la creazione di un account in TOOL ISO52120.
                            </li>
                            <br>
                            <li>
                              <span class="testo_bold">Nullità di queste condizioni</span><br>
                              Ognuna delle condizioni scritte nei Termini e condizioni di utilizzo è autonoma e
                              indipendente.
                              Anche nel caso in cui una di esse venga dichiarata non valida, le altre condizioni
                              manterranno
                              la loro validità ed il loro scopo.
                            </li>
                            <br>
                            <li>
                              <span class="testo_bold">Modifica dei termini e condizioni generali di utilizzo</span><br>
                              Schneider Electric è libera di modificare i termini generali e le condizioni di utilizzo
                              in
                              qualsiasi momento. È quindi consigliabile per l’Utente di consultare regolarmente la
                              versione
                              più recente dei Termini generali e condizioni di utilizzo.
                            </li>
                          </ul>

                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">RISOLUZIONE E RECESSO</span><br>
                          In caso di violazione delle presenti condizioni generali di utilizzo, ciascuna parte può
                          dichiarare
                          per iscritto l’annullamento o la cessazione delle stesse.
                          <br><br>
                          Inoltre, i presenti termini e condizioni di utilizzo generali cessano di avere validità per
                          l’Utente
                          in caso di cancellazione dell’account TOOL ISO52120.
                          <br><br>
                          Al momento della registrazione on-line, l’Utente accetta di fornire informazioni accurate e
                          complete. L’Utente potrà anche mantenere tali informazioni aggiornate nel tempo. Se un Utente
                          fornisce informazioni false, incomplete, inesatte o non aggiornate, Schneider Electric può
                          legalmente sospendere l’account Utente, nonché l’accesso a tutto o una parte dell’App
                          immediatamente
                          ed a tempo indeterminato.
                        </li>
                        <br>
                        <li>
                          <span class="testo_bold">LEGGE APPLICABILE E ATTRIBUZIONE DI GIURISDIZIONE</span><br>
                          Il presente contratto è regolato dalla legge italiana.
                          <br><br>
                          Le condizioni generali di utilizzo sono scritte in italiano.
                          <br><br>
                          Le parti si impegnano a fare ogni sforzo per risolvere amichevolmente eventuali controversie
                          che
                          possono sorgere dall’interpretazione, esecuzione e / o risoluzione di questi Termini e
                          Condizioni
                          generali di utilizzo.
                          <br><br>
                          In caso di mancata risoluzione amichevole, suddetta controversia è sottoposta alla
                          giurisdizione
                          esclusiva del foro competente con riferimento alla sede di Schneider Electric S.p.A.
                        </li>
                      </ul>
                      <br>
                    </div>
                  </div>
                  <div class="flex flex-col text-xs input-labelized  login_input">
                    <div class="flex flex-row items-center justify-left">
                      <input v-model="tosform.data.tc" type="checkbox" class="mr-1 -mt-1 "> Accetto le condizioni
                      d'uso
                    </div>

                    <div class="flex flex-col">
                      <small class="block text-red-600 text-xs">{{ tosform.errors.tc }}</small>
                    </div>
                  </div>




                  <div class="flex flex-col items-center justify-center mt-5 mb-8">
                    <button v-on:click="accept_usage_license()"
                      class="flex items-center justify-center text-center text-sm rounded azzurro_schneider text-white arial_rounded p-2 sm:w-1/2 w-full  hover:bg-zinc-700">
                      ACCETTO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="page == 'request-access'" class="text-center mt-2 px-5">
          <div class="flex flex-col text-lg leading-6 text-zinc-800  mb-2 mt-5">
           <span class="arial_rounded">È necessario richiedere l'accesso per utilizzare il Tool ISO52120</span><br>
           <span class="text-sm ">L'accesso a questo Tool è disponibile solo previo richiesta che verrà processata dal team SE. Per richiedere l'accesso clicca sul pulsante "RICHIEDI ACCESSO", altrimenti clicca
            sul pulsante "ESCI" se non desideri continuare

           </span>
          </div>
          <div class="flex flex-col text-sm text-zinc-800">
            <div class="flex flex-row items-center justify-center mt-5 mb-8">
              <a href="/user/logout" class="flex items-center justify-center text-center text-sm rounded azzurro_schneider text-white arial_rounded p-2 mr-3 hover:bg-zinc-700">
        
                    ESCI

                  </a>
              <button v-on:click="request_access()"
                class="flex items-center justify-center text-center text-sm rounded verde_schneider ml-3 text-white arial_rounded p-2 hover:bg-zinc-700">
                RICHIEDI ACCESSO
              </button>
            </div>
          </div>
        </div>

        <div v-if="page == 'request-access-pending'" class="text-left mt-2  px-5">
          <div class="flex flex-col  text-base text-zinc-800  mb-5 text-center">
            <span class="arial_rounded"> Stiamo processando la tua richiesta d'accesso</span><br>
           
            <span class="text-sm ">Ti verrà inviata una e-mail di notifica non appena la tua richiesta verrà accettata. 

           </span>
          </div>
          <div class="flex flex-col text-sm text-zinc-800">

            <div class="flex flex-col items-center justify-center mt-5 mb-8">
              <a href="/user/logout" class="flex items-center justify-center text-center text-sm rounded azzurro_schneider text-white arial_rounded p-2 mr-3 hover:bg-zinc-700">
                ESCI
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthenticationForm',
  data() {
    return {
      shown: false,
      page: 'login',
      user: {},
      access_status: 'unknown',
      usage_license_accepted: false,
      tosform: {
        data: {
          tc: false
        },
        errors: {}
      }
    };
  },
  methods: {
    open_page: function (page) {
      this.page = page;
      this.shown = true;
    },
    _handle_mandatory_modals: function () {

      let self = this;

      if (self.access_status == 'not-requested') {
        self.open_page("request-access");
        return;
      }

      if (self.access_status == 'pending') {
        self.open_page("request-access-pending");
        return;
      }

      if (!self.usage_license_accepted) {
        self.open_page("accept-usage-license");
        return;
      }
    },
    load_user: function () {
      let self = this;
      axios.get('/api/user/profile')
        .then(function (res) {
          self.user = res.data.user;
          self.usage_license_accepted = res.data.usage_license_accepted;
          self.access_status = res.data.access_status;

          self._handle_mandatory_modals();

        })
        .catch(function (err) {
          // If can't load user, do nothing.
        })
    },
    is_logged_in: function () {
      return this.user.id != undefined;
    },
    request_access: function () {
      let self = this;
      axios.post('/api/user/request-access')
        .then(function (res) {
          if (res.data.status == "success") {
            self.open_page("request-access-pending");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    accept_usage_license: function () {
      let self = this;
      axios.post('/api/user/accept-usage-license', {
        accepted: self.tosform.data.tc
      })
        .then(function (res) {
          if (res.data.status == "success") {
            window.location = '/';
          } else {
            self.tosform.errors.tc = "Devi accettare le condizioni d'uso";
          }
        })
        .catch(function (err) {
          console.log(err);
        })
    }
  },
  mounted: function () {
    this.load_user();
  }
};
</script>

<style scoped></style>
