<script>


// Trampoline for Vue components
// This file is used to import all Vue components and export them as a single object
// This is useful for importing all components in a single line

// Import all components
import AuthenticationForm from './authentication_form.vue';
import SavedProjects from './saved_projects.vue';
import SurveyWizard from './survey_wizard.vue';
import UserManagement from './user_management.vue';

// Export all components
export default {
    AuthenticationForm,
    SavedProjects,
    SurveyWizard,
    UserManagement
};
</script>